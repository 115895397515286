<template>
  <div class="html_content" v-html="info.content" />
</template>

<script>
import { depositRuleInfo } from '../api/deposit'
export default {
  name: "MarginRuleIp",
  data(){
    return{
      info:{}
    }
  },
  methods:{
    initData(id) {
      depositRuleInfo({id:id}).then((res) => {
          console.log(JSON.stringify(res))
          this.info = res.data
      });
    },
  },
  mounted(){
    document.title = "履约金规则"
    let query = this.$route.query;
    console.log(JSON.stringify(query))
    let SKLM_WX_USER_TOKEN = this.$route.query.sklmWxUserToken
    localStorage.setItem("SKLM_WX_USER_TOKEN",SKLM_WX_USER_TOKEN)
    let id = this.$route.query.id
    this.initData(id);
  },
}
</script>

<style scoped>
  .html_content{
    padding: 2%;
  }
</style>
